import { useEffect, useState } from "react";

import { useRecoilState } from "recoil";

import SelectBox from "../layouts/parts/SelectBox";

import JobListItem from "@/components/layouts/top/JobListItem";
import useAuth from "@/hooks/useAuth";
import useToast from "@/hooks/useToast";
import { jobTypeIdState } from "@/stores/atoms/jobTypeIdAtom";
import { locationState } from "@/stores/atoms/locationAtom";
import { masterState } from "@/stores/atoms/masterAtom";
import { userSelector } from "@/stores/selectors/userSelector";
import { JobList } from "@/types/job";
import { Master } from "@/types/master";
import { ApiResponse, apiGet } from "@/utils/api";

function TopPage() {
  const { error } = useToast();
  const [key, setKey] = useRecoilState(locationState);
  const [jobs, setJobs] = useState<JobList[]>([]);
  const [master, setMaster] = useRecoilState(masterState);
  const [jobTypes, setJobTypes] = useState<Master[] | undefined>(master.jobs);
  const [selectedJobTypeId, setSelectedJobTypeId] = useRecoilState(jobTypeIdState);

  const { isReady } = useAuth();
  const [user] = useRecoilState(userSelector);
  const isLogin = user.first_name !== "" || user.last_name !== "";

  const data = jobTypes?.find((v) => v.id === Number(selectedJobTypeId.jobTypeId))?.name;

  useEffect(() => {
    key.component !== "withdraw" && setKey((prevValue) => ({ ...prevValue, component: "" }));
  }, []);

  const fetchJobs = async () => {
    const url = key.tab === "side-job" ? "/api/offers?type=1" : "/api/offers?type=2";
    const finalUrl = selectedJobTypeId?.jobTypeId ? `${url}&job_type_id=${selectedJobTypeId.jobTypeId}` : url;
    const res = await apiGet(finalUrl);
    if ("data" in res) {
      setKey((prevValue) => ({ ...prevValue, path: "" }));
      setJobs(res.data as JobList[]);
    } else {
      error(res.message);
    }
  };

  const fetchJobTypes = async () => {
    try {
      const res: ApiResponse<Master[]> = await apiGet<Master[]>("/api/job-types");
      if (res.success) {
        if ("data" in res) {
          setMaster({ jobs: res.data });
          setJobTypes(res.data);
        }
        return;
      } 
      console.error(res.message);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, [selectedJobTypeId.jobTypeId]);

  useEffect(() => {
    fetchJobs();
    fetchJobTypes();
  }, [key.tab]);

  if (!isReady) return <></>;

  return (
    <div className='overflow-x-hidden font-sans'>
      {/* ログインユーザーのみ表示 */}
      {(user.first_name !== "" || user.last_name !== "") && key.tab === "career-change" && (
      <div className='flex flex-col w-screen items-center justify-center bg-blue-100 p-4 py-8 pt-8 mb-2 mt-2 text-[#2c3c6c]'>
        <div className="flex flex-col">
          <span className="text-xl">あなたのキャリアに合わせた求人を専門コンサルタントがご提案</span>
          <span className='text-xs'>※非公開の求人もご紹介できる場合があります</span>
        </div>
        <a className="font-bold text-xl pt-3 underline after:content-[''] after:inline-block after:ml-2 after:w-3 after:h-3 after:bg-[url('/public/images/external-link-icon.png')] after:bg-contain after:bg-no-repeat" href="https://app.spirinc.com/t/hSpBAsmos7UEsp0gW6nAD/as/iLlIxQAhhqcbfBjk4998K/confirm" target="_blank" rel="noopener noreferrer">
          キャリア面談のご予約はこちら
        </a>
      </div>
      )}
      <div className='flex w-screen items-center justify-center bg-blue-100 p-4 pt-12 text-center text-[24px]'>
        {user.first_name !== "" || user.last_name !== "" ? "公開求人" : "新着求人"}
      </div>
      {/* ログインユーザーのみ表示 */}
      { isLogin && (
      <div className={`flex w-screen items-center justify-center bg-blue-100 p-4 py-10 text-center text-[24px] ${jobs.length === 0 ? 'pb-[420px]' : ''}`}>
        <SelectBox
          data={data ?? "職種から選ぶ"}
          options={
            jobTypes
              ? jobTypes.map((v) => ({
                  id: v.id,
                  name: v.name,
                }))
              : [
                  {
                    id: 0,
                    name: "",
                  },
                ]
          }
          select={(value: string | number) => {
            setSelectedJobTypeId({ jobTypeId: value as number });
          }}
        />
          { selectedJobTypeId && (
          <div className='pl-4'>
            <button type="reset" onClick={() => setSelectedJobTypeId({ jobTypeId: undefined })} className="text-sm my-1 h-[40px] rounded-[4px] bg-blue-200 py-3 text-center font-bold leading-5 text-blue-700 hover:bg-blue-100 hover:text-blue-500 sm:w-[106px] md:w-[140px]">設定をクリア</button>
          </div>
          )}
      </div>
      )}
      <div className='flex justify-center bg-blue-100 md:px-12'>
        <div className='flex max-w-[1600px] items-center justify-center pb-[40px] sm:w-screen md:w-full'>
          <div className='grid grid-cols-12'>
            {jobs.map((job) => (
              <div
                key={job.id}
                className='flex justify-center sm:col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-4'
              >
                <JobListItem {...(job as JobList)} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopPage;
